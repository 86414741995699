import React, { useState } from 'react';
import Pica from 'pica';

function ImageResizer() {
  const [resizedImages, setResizedImages] = useState([]);
  const sizes = [1900, 1200, 750, 600, 512, 370, 350, 300, 250, 200, 192, 180, 128, 48, 32, 24, 16];

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      resizeImage(file);
    }
  };

  const resizeImage = (file) => {
    const pica = Pica();
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvasArray = sizes.map(size => {
          const canvas = document.createElement('canvas');
          canvas.width = size;
          
          // Calcular a altura com base na proporção original da imagem
          const originalAspectRatio = img.width / img.height;
          canvas.height = size / originalAspectRatio;
          
          return canvas;
        });

        Promise.all(canvasArray.map(canvas => pica.resize(img, canvas)))
          .then(results => Promise.all(results.map((resizedCanvas, index) =>
            pica.toBlob(resizedCanvas, 'image/jpeg').then(blob => {
              const url = URL.createObjectURL(blob);
              return { size: sizes[index], url };
            })
          )))
          .then(images => setResizedImages(images))
          .catch(error => console.error("Erro no redimensionamento:", error));
      };
    };
  };

  return (
    <div>
      <input type="file" onChange={handleImageUpload} accept="image/jpeg, image/png" />
      <div className='mb-4'>
        {resizedImages.map((image, index) => (
          <div className='mt-3' key={index}>
            <h3>Tamanho: {image.size}px</h3>
            <img src={image.url} alt={`Imagem redimensionada para ${image.size}px`} />
          </div>
        ))}
      </div>

      {resizedImages.length === 0 && (
        <div className="text-secondary">
          <em>Serão gerados ficheiros quadrados com dimensões: 512, 350, 300, 250, 200, 192, 180, 128, 48, 32, 24, 16</em>
        </div>
      )}

    </div>
  );
};

export default ImageResizer;

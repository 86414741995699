import React, {useState} from "react";
import ImageResizer from "./components/ImageResize";
function App() {

  const [image, setImage] = useState(null);
  const [resize, setResize] = useState(false);


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleResize = () => {
    if (image) {
      setResize(true);
    }
  };
  
  

  
  return (
    <div>
      <div className="container">
        <h1 className="display-4 mt-4">Image Size</h1>
        <div>
            {image && <img src={URL.createObjectURL(image)} alt="Preview" />}
            <ImageResizer image={image} />
        </div>

      </div>
    </div>
  );
}

export default App;
